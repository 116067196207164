import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as GatsbyLink } from 'gatsby'
import { Box, Input, List, ListItem,
  Center,
  HStack,
  Text,
  Divider,
  Heading,
  SimpleGrid,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton} from "@chakra-ui/react"
import { 
  ChevronLeftIcon,
  ChevronRightIcon, } from '@chakra-ui/icons'
import Layout from '../components/Layout.js';
import Seo from '../components/seo_metadata.js';

const CalenderTabs = ({ data }) => {
  let releseDatesYear = [];
  data.forEach((_data)=>{
    var sliceYear = (_data).slice(0,4);
    if(releseDatesYear.indexOf(sliceYear) == -1){
      releseDatesYear.push(sliceYear);
    }
  })
  releseDatesYear.sort();

  let releseDates = [];
  releseDatesYear.forEach((_year) =>{
    var monthcontens = [];
    data.forEach((_data)=>{
      var sliceYear = (_data).slice(0,4);
      if(_year == sliceYear){
        var sliceMonth = (_data).slice(4,6);
        if(monthcontens.indexOf(sliceMonth) == -1){
          monthcontens.push(sliceMonth);
        }
      }
    })
    monthcontens.sort();
    releseDates.push({year:_year,month:monthcontens});
  })

  const [tabIndex, setTabIndex] = useState(releseDatesYear.length-1)
  const [LeftDisable, setLeftDisable] = useState(true)
  const [RightDisable, setRightDisable] = useState(false)

  const handleLeftChange = (event) => {
    var index = tabIndex-1;
    if(index <= 0) {
      index = 0;
      setLeftDisable(false);
    }
    if(index != 0) setLeftDisable(true);
    if(index != releseDates.length - 1) setRightDisable(true);
    setTabIndex(index)
  }

  const handleRightChange = (event) => {
    var index = tabIndex+1;
    if(index >= releseDates.length - 1) {
      index = releseDates.length - 1;
      setRightDisable(false);
    }
    if(index != 0) setLeftDisable(true);
    if(index != releseDates.length - 1) setRightDisable(true);
    setTabIndex(index)
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
  }

//   console.log(releseDates);
  var monthArray = 
    ["01","02","03",
    "04","05","06",
    "07","08","09",
    "10","11","12"];

  return (
    <>
    
    <Tabs index={tabIndex} onChange={handleTabsChange} border={"1px solid gray"}>
      <HStack justifyContent="space-between" borderBottom={"1px solid gray"}>
        <IconButton icon={<ChevronLeftIcon />} isDisabled={!LeftDisable} onClick={handleLeftChange}/>
        <Box>{releseDates[tabIndex].year}年</Box>
        <IconButton icon={<ChevronRightIcon />} isDisabled={!RightDisable} onClick={handleRightChange}/>
      </HStack>
      <TabList display="none">
        {releseDates.map((tab, index) => (
          <Tab key={index}>{tab.year}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {releseDates.map((tab, index) => (
          <TabPanel p={4} key={index}>
            <SimpleGrid columns={3}  spacing={5}>
              {monthArray.map((mon,index)=>(
                
                <Box border={"1px solid #eebd28"} bg="#ffe89d">
                  <Center p={2} fontWeight="bold">
                {
                  tab.month.indexOf(mon) == -1 ? 
                  <Text color="gray.300">{parseInt(mon)}月</Text>
                  : 
                  <GatsbyLink to={"../relese/" + tab.year + mon + "/1"}>
                    <Text>{parseInt(mon)}月</Text>
                  </GatsbyLink>
                }
                  </Center>
                </Box>
                
              ))}
            </SimpleGrid>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
    </>
  )
}

const SearchResult = props => {
  let tempData = [];
  let graphqlResult = useStaticQuery(graphql`
    query {
      allWpPost(
        sort: {fields: date, order: DESC}
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "pdata"}}}}}
      ) {
        nodes {
          uri
          title
          id
          date(formatString: "YYYY.MM.DD")
          pachiACF {
            kensakuMaker
            categrizetext
            kensakuWords
          }
          tags {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `)

  let makerNames = [];
  let makerSlugs = [];
  let num = 1;

  let tagNames = [];
  let tagSlugs = [];

  let releseDates = [];

  graphqlResult.allWpPost.nodes.forEach(async node => {

    tempData.push(node);

    if(makerNames.indexOf(node.pachiACF.kensakuMaker) == -1){
      makerNames.push(node.pachiACF.kensakuMaker)
      makerSlugs.push("number"+num);
      num++;
    }
    if(releseDates.indexOf(node.pachiACF.categrizetext) == -1){
      releseDates.push(node.pachiACF.categrizetext)
    }

    node.tags.nodes.forEach( tag => {
      if(tagNames.indexOf(tag.name) == -1){
        tagNames.push(tag.name)
        tagSlugs.push(tag.slug)
      }
    })

  });
  
  

  const allPosts = tempData;

	const emptyQuery = ""
	const [state, setState] = useState({
	  filteredData: [],
	  query: emptyQuery,
	})

  function hankaku2Zenkaku(str) {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
  }

	const handleInputChange = event => {
	  const query = event.target.value;
    var spaceQuerys = query.replace(/　/g, " ").split(" ");
    const spaceQuerysHankaku = spaceQuerys.map((str)=>(
      hankaku2Zenkaku(str)
    ));
    const posts = allPosts || [];
    

	  const filteredData = posts.filter(post => {
      // const title = hankaku2Zenkaku(post.title)
      const title = hankaku2Zenkaku(post.title + " " + post.pachiACF.kensakuWords)
      
      var flag = false;
      for(let i = 0; i < spaceQuerysHankaku.length; i++){
        var str = spaceQuerysHankaku[i];
        if(title.toLowerCase().includes(str.toLowerCase())){
          flag = true;
        }else{
          flag = false;
          break;
        }
      }
	    return (
	      flag
	    )
	  })

	  setState({
	    query,
	    filteredData,
	  })
	}
  
	const { filteredData, query } = state
	const hasSearchResults = filteredData && query !== emptyQuery
	const result = hasSearchResults ? filteredData : []

	return (
    <Layout>
       <Seo 
         title={"パチンカーズネット機種検索"} 
         description={"様々な条件でパチンコ機種の検索ができます。"}
      />

      <Box bgGradient="repeating-linear(135deg, transparent, transparent 1px, rgba(216, 228, 227, 1) 1px, rgba(216, 228, 227, 1) 2px)">
        <Center p={"2vw"}>
          <Heading as="h2" fontSize="4vw" isTruncated>
            機種検索
          </Heading>
        </Center>
      </Box>

    <Box m={3} className="result-inner">
      <Box p={"2vw"}>
        <Heading as="h3" fontSize="2.5vw" isTruncated>
          キーワード検索
        </Heading>
      </Box>

      <Box m={2}>
        <Input className="px-3 py-4 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-base border border-gray-400 outline-none focus:outline-none focus:ring w-full" 
          type="text"
          aria-label="Search"
          placeholder="検索ワードを入力..."
          onChange={handleInputChange}
        />
        <Box m={2}>
          {query !== "" ?
            query + " の検索結果: " + result.length + "件"
            : "ここに検索結果が表示されます"
          }
        </Box>
      </Box>

{/* <List p={"3vw"}>

{
  allPosts.slice(0,10).map(node => {
    const title = node.title;
    const date = node.date;

    return (
      <>
      <Box p={2} >
        <Divider borderColor="gray"/>
      </Box>
      <ListItem m={2} key={node.id+"n"} p={1}>
        <HStack justifyContent="space-between">
          <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
            {date}
          </Text>
        </HStack>
        <GatsbyLink to={node.uri} >
          <Text fontWeight="bold">{title}</Text>
        </GatsbyLink>
      </ListItem>
      </>
    )
  })
}
</List> */}


        <List p={"3vw"}>
          {result && result.map(x => {
            const title = x.title;
            const date = x.date;
            return (
              <>
              <Box p={1} >
                <Divider borderColor="gray"/>
              </Box>
              <ListItem  m={2} key={x.uri}  p={1}>
                <HStack justifyContent="space-between">
                  <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                    {date}
                  </Text>
                </HStack>
                <GatsbyLink to={x.uri} >
                  <Text fontWeight="bold">{title}</Text>
                </GatsbyLink>

              </ListItem>
              </>
            )
          })}
        </List>
    </Box>

    <Box m={2}>
      <Box p={"2vw"}>
        <Heading as="h3" fontSize="2.5vw" isTruncated>
          最新機種
        </Heading>
      </Box>
      <List p={"3vw"}>
        {allPosts.slice(0,10).map(node => {
          const title = node.title;
          const date = node.date;
          let _tagnames = node.tags.nodes.map(node => {
            return {name:node.name,slug:node.slug};
          });

          return (
          <>
          <Box p={2} >
            <Divider borderColor="gray"/>
          </Box>
          <ListItem m={2} key={node.id+"n"} p={1}>
            <HStack justifyContent="space-between">
              <HStack>
              {_tagnames &&
                _tagnames.map(tagname=> {
                  return (
                  <Text 
                    p="0.5"
                    borderRadius="md" 
                    bgGradient="repeating-linear(135deg, transparent, transparent 1px, rgba(216, 228, 227, 1) 1px, rgba(216, 228, 227, 1) 2px)" 
                    fontWeight="bold" 
                    textTransform="uppercase"
                    fontSize="sm" 
                    letterSpacing="wide" 
                    color="gray.600" >
                    <Text fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                      {tagname.name}
                    </Text>
                  </Text>
                  )
                })
              }
              </HStack>
              
              
              <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                {date}
              </Text>
            </HStack>
            <GatsbyLink to={node.uri} >
              <Text fontWeight="bold">{title}</Text>
            </GatsbyLink>
          </ListItem>
          </>
        )}
        )}
      </List>
    </Box>

    <Box m={2}>
      <Box p={"2vw"}>
        <Heading as="h3" fontSize="2.5vw" isTruncated>
          メーカー別
        </Heading>
      </Box>
      <SimpleGrid p={"3vw"} minChildWidth="120px" spacing={4}>
        {makerNames.map((node,index) => (
          <Box border={"1px solid #eebd28"} bg="#ffe89d" key={node+"m"}>
            <GatsbyLink to={"../maker/" + makerSlugs[index] + "/1"}>
              <Text p={1}>{node}</Text>
            </GatsbyLink>
          </Box>
        ))}
      </SimpleGrid>
    </Box>

    <Box m={2}>
      <Box p={"2vw"}>
        <Heading as="h3" fontSize="2.5vw" isTruncated>
          機種タイプ(タグ)から探す
        </Heading>
      </Box>
      <SimpleGrid p={"3vw"} minChildWidth="120px" spacing={4}>
        {tagNames.map((node,index) => (
          <Box border={"1px solid #eebd28"} bg="#ffe89d" key={node+"m"}>
            <GatsbyLink to={"../tag/" + tagSlugs[index] + "/1"}>
              <Text p={1}>{node}</Text>
            </GatsbyLink>
          </Box>
        ))}
      </SimpleGrid>
    </Box>

    <Box m={2}>
      <Box p={"2vw"}>
        <Heading as="h3" fontSize="2.5vw" isTruncated>
          導入年月で探す
        </Heading>
      </Box>
      <Box p={"3vw"}>
        <CalenderTabs data={releseDates}/>
      </Box>
    </Box>
    </Layout>
	)
}

export default SearchResult